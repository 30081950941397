// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material'as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$boudl-primary: mat.define-palette(mat.$indigo-palette);
$boudl-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$boudl-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$boudl-theme: mat.define-light-theme((color: (primary: $boudl-primary,
      accent: $boudl-accent,
      warn: $boudl-warn,
    )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($boudl-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.mat-mdc-form-field-error {
  position: relative;
  left: -17px;
  top: 3px;
  font-size: 14px;
}

.mdc-text-field--outlined .mat-mdc-form-field-infix,
.mdc-text-field--no-label .mat-mdc-form-field-infix {
  padding-top: 12px !important;
  padding-bottom: 10px !important;
  min-height: 48px;
}

.mat-mdc-menu-panel {
  &.custom-menu {
    min-width: 160px;
    margin-top: 10px;

    .mat-mdc-menu-content {
      padding: 0 !important;

      .mat-mdc-menu-item {
        .mat-mdc-menu-item-text {
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          font-size: 15px;

          span {
            display: flex;
            align-items: center;
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-size: 15px;

            img {
              margin-right: 10px;
            }
          }
        }
      }

      .mdc-list-item {
        min-height: 40px;
        padding-left: 12px;
        padding-right: 12px;

        .mdc-list-item__primary-text {
          font-family: 'Poppins', sans-serif;
          font-size: 13px;
          font-weight: 600;
        }
      }
    }
  }
}

// .mat-mdc-unelevated-button.mat-primary{
//   background:#BD9B52!important;
// }
button {
  &.cancel-btn {
    &.mat-primary {
      background: #fff;
      color: #BD9B52;
      border: solid 1px #BD9B52;
      margin-right: 15px;
    }
  }

  &.submit-btn {
    &.mat-primary {
      background: #BD9B52;
      color: #fff;
      border: solid 1px #BD9B52;
    }
  }
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background-color: #BD9B52 !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: #BD9B52 !important;
}

.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: #BD9B52 !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: #BD9B52 !important;
}

div.mat-mdc-select-panel {
  padding: 0 !important;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #BD9B52 #bd9b524d;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-clip: content-box;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #BD9B52;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #BD9B52;
  border-radius: 15px;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
  background-color: #BD9B52;
}

.cms_header {
  .cms-icon {
    .mat-icon {
      color: #BD9B52 !important;
    }
  }
}

.cms_body {
  border: 1px solid #ccc !important;
  border-radius: 8px;
}

.ql-editor {
  min-height: 180px;
}

.ql-container {
  font-size: 14px !important;
}

.owl-theme {
  .owl-nav {
    width: 100%;
    position: absolute;
    top: 50%;
    margin-top: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .owl-prev {
      font-size: 30px;
      line-height: 24px;
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
      transition: 0.3s ease-in;

      &:hover {
        background: #BD9B52;
      }
    }

    .owl-next {
      font-size: 30px;
      line-height: 24px;
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
      transition: 0.3s ease-in;

      &:hover {
        background: #BD9B52;
      }
    }
  }
}

.table-container {
  .mat-sort-header-arrow {
    display: none !important;
  }
}


/* Change the border focused color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #BD9B52 !important;
}

/* Change the border hover color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #BD9B52 !important;
}
.timepicker {
  .mat-toolbar.mat-primary {
    background: #BD9B52;
  }
}

html .mat-mdc-mini-fab.mat-primary {
  background: #BD9B52;
}

.ngx-mat-timepicker-dialog {
  .mat-mdc-dialog-actions {
    &.mdc-dialog__actions {
      button {
        color: #BD9B52;
      }
    }
  }
}
input[type="search"]::-webkit-search-decoration:hover,
input[type="search"]::-webkit-search-cancel-button:hover {
  cursor: pointer;
}